import { AppBar, Button, Divider, Grid2, Link, Toolbar } from "@mui/material";
import { useCallback } from "react";
import { Outlet } from "react-router";
import { useAuth } from "../shared/auth/hooks";
import { redirectToLoginSignin } from "../shared/auth/internal/redirects";
import FullScreenLoader from "../shared/components/fullScreenLoader/FullScreenLoader";
import GlobalNotificationErrorSnackbar from "../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../shared/components/GlobalNotificationSnackbar";
import HorizontalFill from "../shared/components/HorizontalFill";
import { NotificationContextProvider } from "../shared/contexts/NotificationContext";
import { MainMenu } from "./menu/MainMenu";

export default function App() {
  const onAuthenticated = useCallback(() => {
    //empty
  }, []);

  const isAuthenticated = useAuth(onAuthenticated, redirectToLoginSignin);

  if (!isAuthenticated) {
    return <FullScreenLoader title="Please wait." subtitle="You are being redirected for Authentication..." />;
  }

  return (
    <NotificationContextProvider>
      <AppBar>
        <Toolbar>
          <HorizontalFill />
          <Button LinkComponent={Link} href="/logout" color="inherit">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Grid2 container width="100%" sx={{ pt: 8 }}>
        <Grid2 width={250}>
          <MainMenu />
        </Grid2>
        <Divider orientation="vertical" />
        <Grid2 container width="100%" flex={1}>
          <Outlet />
        </Grid2>
      </Grid2>
      <GlobalNotificationSnackbar />
      <GlobalNotificationErrorSnackbar />
    </NotificationContextProvider>
  );
}
