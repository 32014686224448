import { Grid2, Stack, Typography } from "@mui/material";
import IMG from "../images/icons_km_fish.gif";

const MainPage = () => {
  return (
    <Grid2 container width="100%" sx={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Stack alignItems={"center"}>
        <Typography variant="h1">Welcome to the Aquarium!</Typography>
        <img src={IMG} alt="logo" />
      </Stack>
    </Grid2>
  );
};

export default MainPage;
