import { useEffect } from "react";
import { redirectToLogout } from "../../shared/auth/internal/redirects";
import FullScreenLoader from "../../shared/components/fullScreenLoader/FullScreenLoader";
import { useLocalization } from "../../shared/hooks/useLocalization";

const LogoutContainer = () => {
  const locale = useLocalization();
  const page = locale.pages.logout;

  useEffect(() => redirectToLogout(), []);

  return <FullScreenLoader title={page.title} subtitle={page.subtitle} />;
};

export default LogoutContainer;
