import { Grid2, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo, useState } from "react";
import ScrollableFlexContainer from "../../shared/components/ScrollableFlexContainer";
import SearchField from "../../shared/components/inputs/SearchField";
import Icons from "./Icons";

const IconsPage = () => {
  const [color, setColor] = useState<"primary" | "secondary" | "none">("none");
  const [group, setGroup] = useState<string>("all");
  const [search, setSearch] = useState<string>("");

  const icons = useMemo(() => Icons(), []);
  const groups = useMemo(() => {
    return [
      "all",
      ...icons
        .map((i) => i.group)
        .reduce((acc: string[], curr: string) => {
          if (acc.includes(curr)) return acc;
          return [...acc, curr];
        }, []),
    ];
  }, [icons]);

  const groupedIcons = useMemo(() => {
    if (group === "all") return icons;
    return icons.filter((i) => i.group === group);
  }, [group, icons]);

  const searchedIcons = useMemo(() => {
    if (!search) return groupedIcons;
    return groupedIcons.filter((i) => i.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, groupedIcons]);

  return (
    <ScrollableFlexContainer>
      <Stack spacing={3} p={3} alignItems={"center"} direction={"row"}>
        <ToggleButtonGroup
          value={group}
          exclusive
          sx={{ display: "grid", gridTemplateColumns: `repeat(${groups.length}, 1fr)` }}
          onChange={(_, value) => {
            if (value === null) return;
            setGroup(value);
          }}
        >
          {groups.map((group) => {
            return (
              <ToggleButton key={group} value={group}>
                {group}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <SearchField onSearch={setSearch} />
        <Grid2 flexGrow={1} />
        <ToggleButtonGroup
          value={color}
          exclusive
          sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)" }}
          onChange={(_, value) => {
            if (value === null) return;
            setColor(value);
          }}
        >
          <ToggleButton value="none">None</ToggleButton>
          <ToggleButton value="primary">
            <Typography variant="subtitle2" color="primary">
              Primary
            </Typography>
          </ToggleButton>
          <ToggleButton value="secondary">
            <Typography variant="subtitle2" color="secondary">
              Secondary
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Grid2 container width="100%" spacing={2} p={3}>
        {searchedIcons.map((item, index) => {
          return (
            <Stack
              key={item.name + index}
              alignItems={"center"}
              justifyContent={"center"}
              spacing={2}
              p={1}
              border={(theme) => "1px dotted " + theme.palette.divider}
            >
              <item.Icon color={color === "none" ? undefined : color} />
              <Stack spacing={0.5} alignItems={"center"}>
                <Typography variant="caption" color={"secondary"}>
                  {item.name}
                </Typography>
                <Typography variant="caption" color={grey[500]}>
                  {item.group}
                </Typography>
              </Stack>
            </Stack>
          );
        })}
      </Grid2>
    </ScrollableFlexContainer>
  );
};

export default IconsPage;
