import { AuthSettings } from "../shared/auth/authTypes";
import { importEnvVariable } from "../shared/variables";

export const importAuthSettings = (): AuthSettings => ({
  authEndpoint: importEnvVariable("REACT_APP_ADMIN_PORTAL_AUTH_ENDPOINT"),
  userFlowSignIn: "",
  userFlowSignUp: "",
  userFlowPasswordReset: "",
  clientId: importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID"),
  portalAccessScope: importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE"),
  authCookieNames: "x-entrilia-ap-authorized",
  refreshCookieNames: "x-entrilia-ap-refresh-available",
});
